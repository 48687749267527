.background-modal {
  position: fixed;
  height: 100vh;
  width: 100%;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1200;
  background-color: rgba(0, 0, 0, 0.91);
  padding: 16px;
  .modal-container {
    background-color: #ffffff;
    border-radius: 4px;
    padding: 32px 16px;
    width: 100%;
    max-width: 750px;
    display: flex;
    flex-direction: column;
    .inner-content {
      display: flex;
      flex-direction: column;
      min-width: 50%;
      .button-container {
        margin-top: 24px;
        margin-left: auto;
        :first-child {
          margin-right: 16px;
        }
      }
    }
  }
}
