@import "../../assets/styles";

.create-blog-container {
  width: 100%;
  max-width: $reading-width;
  margin: auto;
  .blog-content {
    width: 100%;
    border: 1px solid #e2e2e2;
    border-top: none;
    min-height: 50vh;
    display: flex;
    flex-direction: column;
    margin-bottom: 100px;
    fieldset {
      padding: 0 16px;
      display: flex;
      flex-direction: column;
      margin-top: 20px;
      border: none;
      .text-field {
        margin-top: 16px;
      }
      &:first-child {
        margin-top: 0;
      }
      .drag-and-drop {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        .icon {
          width: 40px;
          height: 40px;
        }
        & {
          padding: 0;
        }
        &.cover {
          width: 100%;
          min-height: 200px;
          height: fit-content;
          border: 1px solid #e2e2e2;
          background-color: #e2e2e2;
          position: relative;
          img {
            width: 100%;
            height: auto;
            //z-index: 10;
          }
        }

        &.imagesList {
          width: 100%;
          min-height: 600px;
          height: fit-content;
          border: 1px solid #e2e2e2;
          background-color: #e2e2e2;
        }
      }
    }
    .images-container {
      width: 100%;
      img {
        width: 100%;
      }
    }
  }
}

.buttons-container {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  position: fixed;
  bottom: 0;
  right: 0;
  background-image: linear-gradient(rgba(255,255,255,0), rgba(255,255,255,1) 45%, rgba(255,255,255,1));
  z-index: 100;
  padding: 30px 16px 16px;

  .button {
    margin-left: 16px;
  }
}

.modal-background {
  position: fixed;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.80);
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1000;
  padding: 50px 16px 0 16px;
  .blog-post {
    background-color: white;
    overflow-y: scroll;
    width: 100%;
    max-width: $max-width;
    display: flex;
    flex-direction: column;
    .publish-button {
      margin: 16px 16px 16px auto;
    }
  }
}

.hidden-input {
  opacity: 0.0; position: absolute; top: 0; left: 0; bottom: 0; right: 0; width: 100%; height:100%;
z-index: -100;
}
