@import "../../assets/styles/index";

.blog-list-container {
  width: 100%;
  max-width: $reading-width;
  margin: auto;
  display: flex;
  flex-direction: column;
  margin-top: -0.78em;
  .add-post-button {
    width: 100%;
    margin-bottom: 16px;
  }
  .left-toolbar {
    margin-left: auto;
    display: flex;
    align-items: center;
    .button {
      color: #ffffff;
    }
  }
  .list {
    margin-top: 50px;
  }
  .order-by {
    margin-left: auto;
    margin-right: 16px;
    margin-top: 8px;
  }
}
