@import "../../assets/styles/index";

.entire-blog-container {
  width: 100%;
  max-width: $max-width;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  .blog-photo-cover {
    width: 100%;
    height: 50vh;
    max-width: 1200px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    margin-top: $default-margin;
  }

  .cover-caption {
    font-style: italic;
  }


  .blog-container {
    width: 100%;
    max-width: 730px;
    margin-top: 2em;
    padding-left: 24px;
    padding-right: 24px;

    img {
      width: 100%;
    }

    .navigate-blog {
      width: 100%;
      margin-top: 50px;

      .preview-container {
        padding-top: $default-margin;
        border-top: 1px solid #eeeeee;
      }
    }

    .img-container {
      width: 100%;
      margin-top: $default-margin;
    }

    .text-container {
      text-align: justify;
      text-justify: inter-word;

      p {
        margin-bottom: 1.2rem;
        font-size: 18px;
        color: rgb(76, 78, 77);

        &:first-child {
          margin-top: 0;
        }
      }
    }
  }

  .blog-title {
    margin-top: 0;
  }
}
