@import "../../assets/styles/index";

.navigation-bar {
  position: fixed;
  z-index: 99;
  width: 100%;
  height: 64px;
  top: 0;
  left: 0;
  padding-left: 24px;
  padding-right: 24px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.05);
  margin-bottom: 0.78em;
  background-color: #ffffff;
  .content {
    width: 100%;
    height: 100%;
    max-width: $max-width;
    margin: auto;
    display: flex;
    align-items: center;
    a {
      height: 100%;
      display: flex;
      align-items: center;
      text-decoration: none;
      color: unset;
      .title {
        margin-left: 16px;
      }
    }

  }
  @media (min-width: 600px) {
    margin-bottom: 2rem;
  }
  &.mobile {
    position: unset;
  }
}

.navigation-margin {
  width: 100%;
  height: 64px;
  margin-bottom: 2rem;
}
