@import "../../assets/styles/index";

.blog-preview-card {
  margin-bottom: 48px;
  display: flex;
  max-width: 730px;
  border-top: 1px solid #eeeeee;
  position: relative;
  .cover-image {
    height:auto;
    width: 30%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }
  .preview-text {
    width: 70%;
    padding: 0 $default-margin $default-margin;

    .cover-picture {
      width: 100%;
    }
    .short-description {
      margin-top: 10px;
      font-weight: lighter;
    }
    &:hover {
      cursor: pointer;
    }
  }
  &:first-child {
    border-top: none;
  }
  @media (max-width: $xs) {
    .cover-image {
      width: 40%;
    }
    .preview-text {
      width: 60%;
    }
  }
  &.draft {
    opacity: 50%;
  }
}
