.loading-overlay-container {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.9);
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 200;
  top: 0;
  left: 0;
  flex-direction: column;
  color: #ffffff;
  p {
    margin-top: 16px;
  }
}